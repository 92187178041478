.FooterTop {
    padding-top: 10px;
    padding-bottom: 50px;
    border-bottom: 1px solid #262626;
}

.FooterTopBtn button {
    background-color: #f5b800;
    color: #1a1a1a;
    padding: 12px 35px;
    font-family: "Merriweather", serif;
    letter-spacing: 1px;
    border: 2px solid #f5b800;
    font-weight: 800;
    font-size: 13px;
    text-transform: uppercase;
}

.FooterTopBtn button:hover {
    background-color: #1a1a1a;
    color: #f5b800;
    font-family: "Merriweather", serif;
    letter-spacing: 1px;
    padding: 12px 35px;
    font-weight: 800;
    border: 2px solid #f5b800;
    font-size: 13px;
    text-transform: uppercase;
}

.FooterTopText {
    font-family: "Merriweather", serif;
}

.FooterTopText h6 {
    color: #a0a0a0;
    font-weight: 200;
    font-size: 14px;
    line-height: 25px;
}

.MainFooter {
    padding-top: 60px;
}

.MainContentFooter {
    font-family: "Merriweather", serif;
}

.MainContentFooter h3 {
    color: #fff;
    font-size: 13px;
    line-height: 21px;
    text-transform: uppercase;
    letter-spacing: 1px;
    margin-bottom: 22px;
}

.MainContentFooter ul {
    padding: 0rem;
    list-style-type: none;
}

.MainContentFooter ul li {
    margin-bottom: 16px;
}


.MainContentFooter ul li span {
    color: #b8b8b8;
    margin-left: 15px;
    font-size: 13px;
    cursor: pointer;
}

.MainContentFooter ul li span:hover {
    color: #fff;
    margin-left: 15px;
    cursor: pointer;
    font-size: 13px;
}


.MainContentFooterSecond {
    font-family: "Merriweather", serif;
}

.MainContentFooterSecond h3 {
    color: #fff;
    font-size: 13px;
    margin-left: 10px;
    line-height: 21px;
    text-transform: uppercase;
    letter-spacing: 1px;
    margin-bottom: 22px;
}

.MainContentFooterSecond ul {
    padding: 0rem;
    list-style-type: none;
}

.MainContentFooterSecond ul li {
    cursor: pointer;
    padding: 10px;
    background-color: transparent;
    margin-bottom: 0px;
    align-items: center;
}

.MainContentFooterSecond ul li:hover {
    cursor: pointer;
    padding: 10px;
    background-color: #111;
    align-items: center;
}

.MainContentFooterSecond ul li span {
    color: #b8b8b8;
    margin-left: 15px;
    font-size: 13px;
    cursor: pointer;
}

.MainContentFooterSecond ul li span:hover {
    color: #fff;
    margin-left: 15px;
    cursor: pointer;
    font-size: 13px;
}

.MainContentFooterSecond ul li img {
    max-width: 60px;
    height: 50px;
}


.MainContentFooterThird {
    font-family: "Merriweather", serif;
}

.MainContentFooterThird h3 {
    color: #fff;
    font-size: 13px;
    margin-left: 10px;
    line-height: 21px;
    text-transform: uppercase;
    letter-spacing: 1px;
    margin-bottom: 22px;
}

.MainContentFooterThird ul {
    padding: 0rem;
    list-style-type: none;
}

.MainContentFooterThird ul li {
    padding: 10px;
    margin-bottom: 0px;
}


.MainContentFooterThird ul li span {
    color: #b8b8b8;
    margin-left: 15px;
    font-size: 13px;
    cursor: pointer;
}

.MainContentFooterThird ul li span:hover {
    color: #fff;
    margin-left: 15px;
    cursor: pointer;
    font-size: 13px;
}

.MainContentFooterThird i {
    color: #fff;
}

.FooterLastSection {
    padding: 20px 50px;
    font-weight: 400;
    color: #818181;
    letter-spacing: 0.3px;
    font-size: 12px;
    font-family: "Merriweather", serif;
}

@media screen and (max-width:767px) {
    .FooterLastSection {
        padding: 20px 30px;
        font-weight: 400;
        color: #818181;
        letter-spacing: 0px;
        font-size: 10px;
        font-family: "Merriweather", serif;
    }
}