.ServicesFirstSection {
    height: 505px;
    background-position: 50%, 50%;
}

.TopHeaderFirst {
    font-family: "Merriweather", serif;
    color: #202734;
    font-size: 12px;
    font-weight: 300;
    cursor: pointer;
}

.TopHeaderIcon {
    font-family: "Merriweather", serif;
    color: #202734;
    font-size: 12px;
    font-weight: 400;
    margin-left: 10px;
}

.TopHeaderSecond {
    font-family: "Merriweather", serif;
    color: #202734;
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 400;
    margin-left: 10px;
}

.ManagedITServicesMainServiceses {
    padding: 0px 30px;
    margin-left: 0px;
    margin-top: 1px;
    text-align: start;
    font-family: "Merriweather", serif;
    border-radius: 5px;
}

.ManagedITServicesMainServiceses h2 {
    font-size: 20px;
    color: #000;
    padding-bottom: 18px;
    border-bottom: 2px dashed #FFD800;
    font-weight: 600;
    margin-bottom: 20px;
}

.ManagedITServicesMainServiceses h6 {
    font-size: 14.5px;
    color: #252525;
    line-height: 24px;
    font-weight: 400;
}

.ManagedITServicesMainServiceses p {
    font-size: 14.5px;
    color: #252525;
    cursor: pointer;
    font-weight: 400;
}

.ManagedITServicesMainServiceses p:hover {
    font-size: 14.5px;
    color: #111;
    font-weight: 400;
}

.ManagedITServicesMainServiceses p img{
    width: 340px;
}

.ManagedItServices {
    font-family: "Saira", sans-serif;
    margin-top: 25px;
    margin-bottom: 10px;
}

.ManagedItServices h2 {
    font-size: 1.3rem;
    font-family: "Merriweather", serif;
    font-weight: 600;
    margin-top: 18px;
    margin-bottom: 15px;
    color: #252525;
}

.ManagedItServices p {
    font-size: 14px;
    font-family: "Laila", serif;
    color: #808080;
    text-align: justify;
    margin-top: 10px;
    font-weight: 400;
    margin-bottom: 10px;
}

@media screen and (max-width: 1200px) {
    .ManagedITServicesMainServiceses {
        padding: 25px 0px;
        margin-left: 0px;
        text-align: start;
    }

    .ManagedITServicesMainServiceses p img{
        width: 300px;
    }

    .ManagedItServices {
        font-family: "Saira", sans-serif;
        margin-top: 20px;
        margin-bottom: 40px;
    }

    .ManagedItServices p {
        font-size: 16px;
        color: #252525;
        text-align: start;
        font-weight: 400;
        margin-bottom: 6px;
    }

}

@media screen and (max-width: 980px) {
    .ManagedITServicesMainServiceses p img{
        width: 100%;
    }
}

@media screen and (max-width: 580px) {
    .ManagedITServicesMainServiceses {
        padding: 25px 0px;
        margin-left: 0px;
        text-align: start;
    }

    .ManagedItServices {
        font-family: "Saira", sans-serif;
        text-align: start;
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .ManagedItServices h2 {
        font-size: 1.2rem;
        font-weight: 600;
        margin-top: 15px;
        margin-bottom: 15px;
    }

    .ManagedItServices p {
        font-size: 13px;
        color: #252525;
        text-align: start;
        font-weight: 400;
        margin-bottom: 6px;
    }
}