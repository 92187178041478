
/* .testimonials {
    width: 100%;
    height: 100%;
} */

.single-item {
    color: #111;
    margin: 10px 35px 0px;
}


.image {
    width: 100%;
    position: relative;
}

.image:hover {
    width: 100%;
    position: relative;
}

.image img {
    width: 100%;
    height: auto;
    display: block;
}

.contentAll {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
    background: rgba(239, 180, 5, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    opacity: 0;
    transition: 0.6s;
}

.contentAll:hover {
    opacity: 1;
    cursor: pointer;
}

.contentAll h1 {
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 2.5px;
    text-transform: uppercase;
    overflow: hidden;
    color: #fff;
    font-family: "Laila", serif;
    font-style: normal;
}

.contentAll h5 {
    font-family: "Laila", serif;
    font-weight: 600;
    line-height: 22px;
    font-style: normal;
    font-size: 14px;
    text-transform: uppercase;
    overflow: hidden;
    color: #fff;
    margin-bottom: 16px;
}

.contentAll p {
    max-width: 300px;
    font-family: "Laila", serif;
    font-style: normal;
    font-weight: 600;
    font-style: normal;
    font-size: 16px;
    line-height: 1.6;
    margin-bottom: 20px;
    color: #fff;
}



.btn {
    display: flex;
    text-align: center;
    font-family: "Laila", serif;
    justify-content: center;
    align-items: center;
    background-color: #f5b800;
    padding: 14px 40px;
    border-radius: 0px;
    font-weight: 600;
    font-size: 14px;
    color: #1a1a1a;
}


.btn:hover {
    display: flex;
    text-align: center;
    font-family: "Laila", serif;
    justify-content: center;
    align-items: center;
    background-color: #1a1a1a;
    padding: 14px 40px;
    font-weight: 600;
    border-radius: 0px;
    font-size: 14px;
    color: #f5b800;
}



.btnResp {
    display: flex;
    width: 200px;
    text-align: center;
    font-family: "Laila", serif;
    justify-content: center;
    align-items: center;
    background-color: #f5b800;
    padding: 15px 40px;
    text-decoration: none;
    border-radius: 0px;
    font-weight: 600;
    font-size: 12px;
    color: #1a1a1a;
}


.btnResp:hover {
    display: flex;
    width: 200px;
    text-align: center;
    font-family: "Laila", serif;
    justify-content: center;
    align-items: center;
    background-color: #1a1a1a;
    padding: 15px 40px;
    text-decoration: none;
    font-weight: 600;
    border-radius: 0px;
    font-size: 12px;
    color: #f5b800;
}


.btn1 span i {
    font-size: 12px;
}


.content>* {
    transition: 0.6s;
}

.content:hover>* {
    transform: translateY(0px);
}



.LeftArrow {
    margin-top: 20px;
    width: 46px;
    height: 46px;
    border-radius: 50px;
    background-color: #1a1a1a;
}

.LeftArrow i {
    font-size: 15px;
    margin-top: 15px;
}

.RightArrow {
    margin-top: 20px;
    width: 46px;
    height: 46px;
    border-radius: 50px;
    background-color: #1a1a1a;
}

.RightArrow i {
    font-size: 15px;
    margin-top: 15px;
}

/* Teronary Operator */



.btnStyle {
    color: #fff;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 1px solid transparent;
    background-color: #1a1a1a;
}

.btnStyle:hover {
    color: #fff;
    border-bottom: 1px solid #f5b800;
}

.newStyle {
    color: #fff;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 1px solid #f5b800;
    background-color: #1a1a1a;
}



.btn3Style {
    color: #fff;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 1px solid transparent;
    background-color: #1a1a1a;
}

.btn3Style:hover {
    border-bottom: 1px solid #f5b800;
}

.btn4Style {
    color: #fff;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 1px solid transparent;
    background-color: #1a1a1a;
}

.btn4Style:hover {
    border-bottom: 1px solid #f5b800;
}

.btn5Style {
    color: #fff;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 1px solid transparent;
    background-color: #1a1a1a;
}

.btn5Style:hover {
    border-bottom: 1px solid #f5b800;
}

.btn6Style {
    color: #fff;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 1px solid transparent;
    background-color: #1a1a1a;
}

.btn6Style:hover {
    border-bottom: 1px solid #f5b800;
}

/* Ternary opertor end */

.RecentProjectMainContent {
    text-align: center;
}

.RecentProjectMainContent h2 {
    font-family: "Merriweather", serif;
    color: #fff;
    font-weight: 700;
    font-size: 30px;
    margin-bottom: 20px;
    line-height: 46px;
}

.RecentProjectMainContent p {
    color: #808080;
    font-family: "Laila", serif;
    font-weight: 400;
    font-size: 20.5px;
    line-height: 27px;
    margin-left: auto;
    margin-right: auto;
    max-width: 700px;
    margin-bottom: 25px;
}

.RecentPostContentServices {
    text-align: start;
    padding: 25px 0px 0px;
    margin-bottom: 30px;
}

.RecentPostContentServices h2 {
    color: #1a1a1a;
    font-family: "Merriweather", serif;
    font-weight: 800;
    line-height: 27px;
    font-size: 15.5px;
    margin-bottom: 5px;
    cursor: pointer;
}

.RecentPostContentServices h2:hover {
    color: #f5b800;
    font-family: "Merriweather", serif;
    font-weight: 800;
    line-height: 27px;
    font-size: 20px;
    margin-bottom: 10px;
}

.RecentPostContentServices p {
    color: #808080;
    font-family: "Laila", serif;
    font-weight: 400;
    font-size: 15px;
    cursor: default;
    margin-bottom: 0px;
}


@media only screen and (max-width: 1200px) {
    .testimonials {
        width: 100%;
        height: 100%;
    }

    .LeftArrow {
        margin-right: 50px;
        margin-top: -10px;
        width: 46px;
        height: 46px;
        border-radius: 50px;
        background-color: #1a1a1a;
    }

    .RightArrow {
        margin-left: 10px;
        margin-top: -10px;
        width: 46px;
        height: 46px;
        border-radius: 50px;
        background-color: #1a1a1a;
    }

}

@media only screen and (max-width: 767px) {

    /* .MainColumn{
        flex: 0 0 auto;
        width: 16.8880%;
    } */

    .testimonials {
        width: 100%;
        height: 100%;
    }

    .LeftArrow {
        margin-right: 0px;
        margin-top: 70px;
        width: 46px;
        height: 46px;
        border-radius: 50px;
        background-color: #1a1a1a;
    }

    .RightArrow {
        margin-left: -30px;
        margin-top: 70px;
        width: 46px;
        height: 46px;
        border-radius: 50px;
        background-color: #1a1a1a;
    }

}

@media only screen and (max-width: 580px) {
    .testimonials {
        width: 100%;
        height: 100%;
    }

    .LeftArrow {
        margin-right: 0px;
        margin-top: -10px;
        width: 46px;
        height: 46px;
        border-radius: 50px;
        background-color: #1a1a1a;
    }

    .RightArrow {
        margin-left: -10px;
        margin-top: -10px;
        width: 46px;
        height: 46px;
        border-radius: 50px;
        background-color: #1a1a1a;
    }

    .single-item {
        color: #111;
        margin: 10px 0px 0px;
    }

    .image img {
        width: 100%;
        height: auto;
        display: block;
    }

    .CompanyName {
        background-color: black;
        font-family: "Merriweather", serif;
        padding: 10px;
        text-align: center;
        color: #fff;
    }

}