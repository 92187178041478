.laila-semibold {
    font-family: "Laila", serif;
    font-weight: 600;
    font-style: normal;
}

.SendButton {
    background-color: #ffd800;
    height: 48px;
    font-size: 12px;
    font-weight: 400;
    padding: 10px 40px;
    color: #1a1a1a;
    border: none;
    text-align: center;
}

.SendButton:hover {
    background-color: #1a1a1a;
    height: 48px;
    font-size: 12px;
    font-weight: 400;
    padding: 10px 40px;
    color: #ffd800;
    border: none;
    text-align: center;
}


.MainContentFooterThird {
    font-family: "Merriweather", serif;
}

.MainContentFooterThird h3 {
    color: #fff;
    font-size: 13px;
    margin-left: 10px;
    line-height: 21px;
    text-transform: uppercase;
    letter-spacing: 1px;
    margin-bottom: 22px;
}

.MainContentFooterThird ul {
    padding: 0rem;
    list-style-type: none;
}

.MainContentFooterThird ul li {
    padding: 10px;
    margin-bottom: 0px;
}


.MainContentFooterThird ul li span {
    color: #b8b8b8;
    margin-left: 15px;
    font-size: 13px;
    cursor: pointer;
}

.MainContentFooterThird ul li span:hover {
    color: #fff;
    margin-left: 15px;
    cursor: pointer;
    font-size: 13px;
}

.MainContentFooterThird i {
    color: #fff;
}

.CommonHoverContact{
    font-size: 15px;
    color: #1a1a1a;
    line-height: 24px;
    font-weight: 500;
}

.CommonHoverContact:hover{
    font-size: 15px;
    color: #337ab7;
    line-height: 24px;
    font-weight: 500;
}

.MainResponsiveFormDown{
    padding-left: 2rem;
}

.MainResponsiveFormCopmanyContact{
    margin-top: 15px;
    margin-bottom: 15px;
}


@media screen and (max-width:990px) {
    .MainResponsiveFormDown{
        padding-left: 0rem;
        margin-top: 30px;
    }

    .MainResponsiveFormCopmanyContact{
        margin-top: 20px;
        margin-bottom: 20px;
    }
}
