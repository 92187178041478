/* .testimonials {
    width: 100%;
    height: 100%;
} */

.single-item {
    color: #111;
    margin: 10px 50px 0px;
}



/* .image {
    width: 100%;
    position: relative;
}

.image:hover {
    width: 100%;
    position: relative;
}

.image img {
    width: 100%;
    height: 100%;
    display: block;
} */

/* .content {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: red;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: 0.6s;
}

.content:hover {
    opacity: 1;
} */



.contentFirstSlide {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
    /* background: rgba(239, 180, 5, 0.8); */
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    opacity: 0;
    transition: 0.6s;
}

.contentFirstSlide:hover {
    opacity: 1;
    /* cursor: pointer; */
}

.content h1 {
    font-size: 5px;
    font-weight: bold;
    /* letter-spacing: 2.5px; */
    text-transform: uppercase;
    overflow: hidden;
    color: #fff;
    font-family: "Laila", serif;
    font-style: normal;
}


.content p {
    max-width: 300px;
    font-family: "Laila", serif;
    font-style: normal;
    font-weight: 300;
    font-style: normal;
    font-size: 16px;
    line-height: 1.6;
    margin-bottom: 20px;
    color: #fff;
}





.btn {
    display: flex;
    text-align: center;
    font-family: "Laila", serif;
    justify-content: center;
    align-items: center;
    background-color: #f5b800;
    padding: 14px 40px;
    border-radius: 0px;
    font-weight: 600;
    font-size: 14px;
    color: #1a1a1a;
}


.btn:hover {
    display: flex;
    text-align: center;
    font-family: "Laila", serif;
    justify-content: center;
    align-items: center;
    background-color: #1a1a1a;
    padding: 14px 40px;
    font-weight: 600;
    border-radius: 0px;
    font-size: 14px;
    color: #f5b800;
}



.btnResp {
    display: flex;
    width: 200px;
    text-align: center;
    font-family: "Laila", serif;
    justify-content: center;
    align-items: center;
    background-color: #f5b800;
    padding: 15px 40px;
    text-decoration: none;
    border-radius: 0px;
    font-weight: 600;
    font-size: 12px;
    color: #1a1a1a;
}


.btnResp:hover {
    display: flex;
    width: 200px;
    text-align: center;
    font-family: "Laila", serif;
    justify-content: center;
    align-items: center;
    background-color: #1a1a1a;
    padding: 15px 40px;
    text-decoration: none;
    font-weight: 600;
    border-radius: 0px;
    font-size: 12px;
    color: #f5b800;
}


.btn1 span i {
    font-size: 12px;
}

/* 
.content>* {
    transform: translateY(25px);
    transition: 0.6s;
}

.content:hover>* {
    transform: translateY(0px);
} */

.carousel-control-next,
.carousel-control-prev {
    position: absolute;
    text-align: center;
    opacity: 1;
}


.LeftArrow {
    margin-right: 70px;
    margin-top: 20px;
    width: 46px;
    height: 46px;
    border-radius: 50px;
    background-color: #1a1a1a;
}

.LeftArrow i {
    font-size: 15px;
    margin-top: 15px;
}

.RightArrow {
    margin-left: 40px;
    margin-top: 20px;
    width: 46px;
    height: 46px;
    border-radius: 50px;
    background-color: #1a1a1a;
}

.RightArrow i {
    font-size: 15px;
    margin-top: 15px;
}

.CommonText {
    color: #fff;
    font-size: 18px;
    font-weight: 800;
    font-family: "Merriweather", serif;
}

.CommonTextP {
    color: #d9d9d9;
    font-size: 16px;
    font-weight: 600;
    font-family: "Laila", serif;
}


@media only screen and (max-width: 1200px) {
    .testimonials {
        width: 100%;
        height: 100%;
    }

    .LeftArrow {
        margin-right: 50px;
        margin-top: -10px;
        width: 46px;
        height: 46px;
        border-radius: 50px;
        background-color: #1a1a1a;
    }

    .RightArrow {
        margin-left: 10px;
        margin-top: -10px;
        width: 46px;
        height: 46px;
        border-radius: 50px;
        background-color: #1a1a1a;
    }

}

@media only screen and (max-width: 767px) {
    .testimonials {
        width: 100%;
        height: 100%;
    }

    .LeftArrow {
        margin-right: 0px;
        margin-top: 70px;
        width: 46px;
        height: 46px;
        border-radius: 50px;
        background-color: #1a1a1a;
    }

    .RightArrow {
        margin-left: -30px;
        margin-top: 70px;
        width: 46px;
        height: 46px;
        border-radius: 50px;
        background-color: #1a1a1a;
    }

}

@media only screen and (max-width: 580px) {
    .testimonials {
        width: 100%;
        height: 100%;
    }

    .LeftArrow {
        margin-right: 15px;
        margin-top: -10px;
        width: 46px;
        height: 46px;
        border-radius: 50px;
        background-color: #1a1a1a;
    }

    .RightArrow {
        margin-left: -10px;
        margin-top: -10px;
        width: 46px;
        height: 46px;
        border-radius: 50px;
        background-color: #1a1a1a;
    }

    .single-item {
        color: #111;
        margin: 10px 5px 0px;
    }

    .image img {
        width: 100%;
        height: auto;
        display: block;
    }

    .CompanyName {
        background-color: black;
        font-family: "Merriweather", serif;
        padding: 10px;
        text-align: center;
        color: #fff;
    }

}