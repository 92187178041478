.Same-Img-Size {
    height: 550px;
}

.carousel-fade .carousel-item.active{
    z-index: 0;
}

.bebas-neue-regular {
    font-family: "Bebas Neue", sans-serif;
    font-weight: 400;
    font-style: normal;
}


.lato-black {
    font-family: "Lato", sans-serif;
    font-style: normal;
}

.merriweather-bold {
    font-family: "Merriweather", serif;
    font-weight: 700;
    font-style: normal;
}

/* Silder Start                 */


.Main-Silder-Content {
    background-color: #fff;
    width: 32.5%;
    position: absolute !important;
    color: #111;
    padding: 30px;
    left: 12%;
    top: 23%;
}

.Main-Silder-Content h2 {
    font-family: "Merriweather", serif;
    font-size: 32px;
    line-height: 40px;
    color: #111;
    margin-bottom: 15px;
    font-weight: 800;
}

.Main-Silder-Content h6 {
    font-size: 16px;
    color: #808080;
    line-height: 27px;
    font-family: "Laila", serif;
    font-weight: 400;
}

.Main-Silder-Content button {
    background-color: #ffd800;
    font-family: "Laila", serif;
    color: #1a1a1a;
    font-weight: 800;
    font-size: 14px;
    text-transform: uppercase;
    padding: 13px 30px;
    float: right;
    position: relative;
    bottom: -50px;
    border: none;
}

.Main-Silder-Content button:hover {
    background-color: #111;
    font-family: "Laila", serif;
    color: #ffd800;
    font-weight: 800;
    font-size: 14px;
    text-transform: uppercase;
    padding: 13px 30px;
    float: right;
    position: relative;
    bottom: -50px;
    border: none;
}

/*                   Silder End                 */

.FeatureMain {
    padding: 45px;
}

.Feature-Content {
    font-family: "Merriweather", serif;
}

.Feature-Content h2 {
    color: #1a1a1a;
    font-weight: 700;
    font-size: 16px;
    margin-bottom: 12px;
    margin-top: 20px;
}

.Feature-Content p {
    color: #808080;
    font-family: "Laila", serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 1.6842;
    margin-bottom: 10px;
}

/*                   Feature End                 */


.ServicesMainContent {
    text-align: center;
}

.ServicesMainContent h2 {
    font-family: "Merriweather", serif;
    color: #1a1a1a;
    font-weight: 700;
    font-size: 30px;
    margin-bottom: 20px;
    line-height: 46px;
}

.ServicesMainContent p {
    color: #808080;
    font-family: "Laila", serif;
    font-weight: 400;
    font-size: 18.5px;
    line-height: 27px;
    margin-left: auto;
    margin-right: auto;
    max-width: 700px;
    margin-bottom: 25px;
}

.SingleService {
    background-color: #fff;
    height: 100%;
    margin-bottom: 60px;
    cursor: pointer;
    box-shadow: 0 0 25px 0 rgba(177, 86, 86, 0.05);
}

.SingleService:hover {
    background-color: #fff;
    height: 100%;
    margin-bottom: 60px;
    transition: all .2s ease-in-out;
    box-shadow: 0 1rem 3rem rgba(0, 0, 0, .175) !important;
}

.Services-Content {
    text-align: center;
    padding: 40px 10px 0px;
}

.Services-Content h2 {
    color: #1a1a1a;
    font-family: "Merriweather", serif;
    font-weight: 700;
    font-size: 14px;
    margin-bottom: 15px;
    line-height: 24px;
    cursor: pointer;
}

.Services-Content h2:hover {
    color: #f5b800;
    font-family: "Merriweather", serif;
    font-weight: 700;
    font-size: 14px;
    margin-bottom: 15px;
    line-height: 24px;
}

.Services-Content p {
    color: #808080;
    font-family: "Laila", serif;
    font-weight: 400;
    font-size: 13px;
    cursor: default;
    line-height: 24px;
    margin-bottom: 10px;
}

.ServicesBtn {
    padding: 30px;
    margin-top: 30px;
    margin-bottom: 30px;
    text-align: center;
}

.ServicesBtn button {
    background-color: #1a1a1a;
    color: #fff;
    padding: 13px 30px;
    font-family: "Laila", serif;
    border: none;
    font-weight: 700;
    font-size: 14px;
    text-transform: uppercase;
}

.ServicesBtn button:hover {
    background-color: #f5b800;
    font-family: "Laila", serif;
    color: #1a1a1a;
    border: none;
    font-weight: 700;
    padding: 13px 30px;
    font-size: 14px;
    text-transform: uppercase;
}

.RecentPostTop {
    font-family: "Merriweather", serif;
}

.RecentPostTop h2 {
    color: #1a1a1a;
    font-size: 23px;
    font-weight: 700;
    line-height: 32px;
}

.RecentPostTop p {
    color: #4b493d;
    font-size: 17px;
    font-weight: 400;
    line-height: 32px;
}

.RecentPostTop button {
    background-color: rgb(26, 26, 26);
    color: rgb(255, 255, 255);
    padding: 14px 30px;
    border: none;
    font-weight: 800;
    font-size: 13px;
    text-transform: uppercase;
}

.RecentPostTop button:hover {
    background-color: rgb(255, 255, 255);
    color: rgb(26, 26, 26);
    padding: 14px 30px;
    font-weight: 800;
    border: none;
    font-size: 13px;
    text-transform: uppercase;
}


.RecentPost-Content {
    text-align: start;
    padding: 25px 0px 0px;
}

.RecentPost-Content h2 {
    color: #1a1a1a;
    font-family: "Merriweather", serif;
    font-weight: 800;
    line-height: 27px;
    font-size: 16px;
    margin-bottom: 15px;
    cursor: pointer;
}

.RecentPost-Content h2:hover {
    color: #f5b800;
    font-family: "Merriweather", serif;
    font-weight: 800;
    line-height: 27px;
    font-size: 16px;
    margin-bottom: 15px;
}

.RecentPost-Content p {
    color: #808080;
    font-family: "Laila", serif;
    font-weight: 400;
    font-size: 14px;
    cursor: default;
    line-height: 24px;
    margin-bottom: 10px;
}

.ClientSays {
    padding: 5px;
    text-align: center;
    font-family: "Laila", serif;
}

.ClientSays-TestimonialSection {
    background-color: #fff;
    box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.05);
    padding: 50px 45px 80px;
}

.ClientSays-TestimonialSection p {
    color: #737373;
    font-size: 16px;
    line-height: 32px;
}

.ClientSaysImge {
    width: 65px;
    height: 65px;
    border-radius: 65px;
    overflow: hidden;
    margin-top: -32px;
}

.ClientSays h3 {
    color: #1a1a1a;
    font-size: 18px;
    margin-top: 20px;
    font-weight: 700;
    margin-bottom: 5px;
    font-family: "Merriweather", serif;
}

.ClientSays h6 {
    color: #9fa3a7;
    font-size: 12px;
    margin-top: 10px;
    letter-spacing: 2.4px;
    text-transform: uppercase;
    font-weight: 700;
    margin-bottom: 15px;
    font-family: "Laila", serif;
}

/* ///////////// whatsApp //////////////// */

.whatsApp {
    position: fixed;
    right: 1rem;
    bottom: 47%;
    animation: zoom-in-zoom-out 1s ease infinite;
}

.whatsApp a i {
    font-size: 30px;
    border-radius: 50%;
    padding: 10px;
    color: #fff;
    background-color: #25D366;
}

/* ///////////// whatsApp //////////////// */

@media screen and (max-width:990px) {
    .FeatureMain {
        padding: 35px;
    }

    .MainText {
        text-align: center;
        padding: 15px;
    }

    .Same-Img-Size {
        height: 400px;
    }

    .Main-Silder-Content {
        background-color: #fff;
        width: 50%;
        position: absolute !important;
        color: #111;
        padding: 30px;
        left: 6%;
        top: 15%;
    }

    .Main-Silder-Content h2 {
        font-family: "Merriweather", serif;
        font-size: 25px;
        line-height: 30px;
        color: #111;
        margin-bottom: 15px;
        font-weight: 800;
    }

    .Main-Silder-Content h6 {
        font-size: 16px;
        color: #808080;
        line-height: 24px;
        font-family: "Laila", serif;
        font-weight: 400;
    }

    .Main-Silder-Content button {
        background-color: #ffd800;
        font-family: "Laila", serif;
        color: #1a1a1a;
        font-weight: 800;
        font-size: 14px;
        text-transform: uppercase;
        padding: 13px 30px;
        float: right;
        position: relative;
        bottom: -50px;
        border: none;
    }

    .Main-Silder-Content button:hover {
        background-color: #111;
        font-family: "Laila", serif;
        color: #ffd800;
        font-weight: 800;
        font-size: 14px;
        text-transform: uppercase;
        padding: 13px 30px;
        float: right;
        position: relative;
        bottom: -50px;
        border: none;
    }
}

@media screen and (max-width:767px) {
    .FeatureMain {
        padding: 10px;
    }

    .Same-Img-Size {
        height: 250px;
    }

    .Main-Silder-Content {
        background-color: #fff;
        display: none;
        width: 50%;
        position: absolute !important;
        color: #111;
        padding: 30px;
        left: 6%;
        top: 15%;
    }
}