.ServicesMainFirst {
    text-align: center;
    margin-top: 63px;
    font-family: "Merriweather", serif;
}

.ServicesMainFirst h2 {
    font-size: 36px;
    line-height: 46px;
    color: #1a1a1a;
    font-weight: 700;
}


.OurServices h2 {
    font-family: "Merriweather", serif;
    font-size: 30px;
    margin-top: 15px;
    margin-bottom: 10px;
    line-height: 36px;
    color: #1a1a1a;
    font-weight: 700;
}

.OurServices p {
    font-family: "Laila", serif;
    font-size: 18px;
    line-height: 28px;
    color: #808080;
    font-weight: 400;
}

.SingleServiceServicePage {
    background-color: #fff;
    height: 100%;
    margin-bottom: 20px;
    cursor: pointer;
    box-shadow: 0 1rem 3rem rgba(0, 0, 0, .175) !important;
}

.AboutService {
    height: 100%;
    padding: 1.3em 1.3em 1.3em 1.3em;
    text-align: center;
    background-color: #fff;
    box-shadow: 0 0rem 2rem rgba(0, 0, 0, .175) !important;
    font-family: "Saira", sans-serif;
    border-radius: 2px;
}

/* .AboutService:hover {
    height: 100%;
    padding: .5em .5em .5em .5em;
    text-align: center;
    background-color: #fff;
    box-shadow: 0 1rem 3rem rgba(0, 0, 0, .175) !important;
    font-family: "Saira", sans-serif;
    border-radius: 2px;
} */

.AboutService h2 {
    font-size: 13.5px;
    font-family: "Merriweather", serif;
    color: #1a1a1a;
    font-weight: 600;
    margin-top: 10px;
    margin-bottom: 15px;
}

.AboutService p {
    font-size: 14px;
    font-family: "Laila", serif;
    color: #808080;
    margin-bottom: 0px;
}

.CommonIconAboutIcon{
    background-color: transparent;
    border: 2px dashed #FFD800;
    text-align: center;
    margin-bottom: 20px;
    color: #fff;
    border-radius: 100px;
    width: 60px;
    height: 60px;
}

.CommonIconAboutIcon img {
    width: 46px;
    padding: 8px;
    text-align: center;
    height: 46px;
}


.OurServices button {
    background-color: #fff;
    border: 1px solid #FFD800;
    color: #111;
    margin-top: 25px;
    padding: 10px 25px;
    font-size: 14px;
    border-radius: 2px;
}

.OurServices button:hover {
    background-color: #111;
    border: 1px solid #111;
    color: #FFD800;
    margin-top: 25px;
    padding: 10px 25px;
    font-size: 14px;
    border-radius: 2px;
}