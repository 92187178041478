.bebas-neue-regular {
    font-family: "Bebas Neue", sans-serif;
    font-weight: 400;
    text-transform: lowercase;
    font-style: normal;
}

.laila-semibold {
    font-family: "Laila", serif;
    font-weight: 600;
    font-style: normal;
}

.LogoFinalSize {
    height: 50px;
    width: 205px;
}

.lato-black {
    font-family: "Lato", sans-serif;
    font-style: normal;
}

.merriweather-bold {
    font-family: "Merriweather", serif;
    font-weight: 700;
    font-style: normal;
}

.header-logo-contact-section {
    padding-top: 10px;
    padding-bottom: 10px;
}

.header-logo-contact-section-btn {
    background-color: #fff;
    border: 2px solid #ffd800;
    padding: 13px 22px;
    text-transform: uppercase;
    font-size: 13px;
    color: #1a1a1a;
}

.header-logo-contact-section-btn:hover {
    background-color: #111;
    border: 2px solid #111;
    padding: 13px 22px;
    text-transform: uppercase;
    font-size: 13px;
    color: #ffd800;
}

nav {
    display: flex;
    justify-content: space-around;
    position: fixed;
    left: 0;
    padding-top: 20px;
    top: 20;
    color: white;
    width: 100%;
    z-index: 1;
    background-color: transparent;
    transition: 0.6s ease-in-out;
}

nav.active {
    box-shadow: 5px 10px 30px rgba(0, 0, 0, 0.157);
    /* background-color: #1F3445; */
    top: 0;
}

.navbar-nav {
    display: flex;
    width: 100%;
    text-transform: uppercase;
    font-family: "Laila", serif;
    font-weight: 500;
}

.navbar-nav .nav-link {
    padding: 17px;
    text-decoration: none;
    font-size: 12px;
    color: #515151;
}

.CommonHeaderSecond{
    color: #1a1a1a;
    font-weight: 400;
    font-size: 14px;
}

.CommonHeaderSecond:hover{
    color: #337ab7;
    font-weight: 400;
    font-size: 14px;
}

/* .nav-link {
    color: #515151;
    text-decoration: none;
    padding: 17px;
    font-size: 12px;
}

.nav-link:hover{
    color: #1a1a1a;
    text-decoration: none;
    padding: 17px;
    font-size: 12px;
} */

.LogoSection span {
    color: #2bc0c1;
    font-size: 40px;
    font-weight: 700;
    line-height: 1.2;
}

.LogoSection h4 {
    color: #fff;
    font-size: 35px;
    font-weight: 700;
    line-height: 1.2;
}

.dropend {
    color: #fff;
    font-weight: 800;
    padding: 10px;
    margin-left: 5px;
}


.dropdown-item {
    color: #fff;
    padding: 16px;
    /* width: 180px; */
}

.dropdown-item:hover {
    background-color: #ff6900;
    /* color: #11b6e4; */
    /* width: 180px; */
}


.dropdown-item.active {
    background-color: transparent;
}

.dropdown .dropdown-menu {
    display: none;
}

.dropdown:hover>.dropdown-menu,
.dropend:hover>.dropdown-menu {
    display: block;
    padding: 0px;
    font-size: .5rem;
    border: none;
    background-color: #ffd800;
    border-radius: 0px;
}

.SocialIcon {
    margin-right: 20px;
    padding: 5px;
}

.SocialIcon i {
    font-size: 13px;
    color: #808080;
    margin-left: 15px;
    border-radius: 25px;
}

.SocialIcon i:hover {
    font-size: 13px;
    color: #ffd800;
    margin-left: 15px;
    border-radius: 25px;
}

@media screen and (min-width: 1200px) {
    .dropend:hover>.dropdown-menu {
        position: absolute;
        top: 0;
        left: 100%;
    }

    .dropend .dropdown-toggle {
        margin-left: 0.5em;
    }
}


@media screen and (min-width: 1000px) {
    .dropend:hover>.dropdown-menu {
        position: absolute;
        top: 0;
        left: 100%;
    }

    .dropend .dropdown-toggle {
        margin-left: 0.5em;
    }
}


@media screen and (max-width: 990px) {
    .navbar>.container-fluid {
        display: flex;
        flex-direction: column;
        align-items: unset;
        justify-content: space-around;
        padding: 20px;
    }

    .navbar-nav .nav-link {
        color: #fff;
        padding: 7px 0px;
        font-size: .8411rem;
    }

    .nav-item {
        border-top: none;
    }

    .nav-item:hover {
        border-top: none;
    }

    .navbar-toggler {
        margin-bottom: 0px;
        margin-top: 0px;
        border-radius: 0px;
        line-height: 1.1;
    }

    /* .navbar-toggler{
        margin-bottom: 20px;
        margin-top: 10px;
        border-radius: 0px;
        line-height: 1.1;
    } */

    .navbar-toggler i {
        background-color: #202734;
        font-size: 1.25rem;
        border: #202734;
        padding: 0.70rem 0.66rem;
        color: #fff;
    }

    .navbar-toggler:focus {
        text-decoration: none;
        box-shadow: none;
    }

    .LogoSection h4 {
        color: #fff;
        font-size: 35px;
        font-weight: 700;
        margin-left: 10px;
        line-height: 1.2;
    }

    .LogoFinalSize {
        height: 100%;
        width: 205px;
    }

    .LogoStyle {
        text-align: center;
    }
}