:root {
    --bg-color: #fff
}

body{
    background-color: var(--bg-color);
}

.color-switcher {
    width: 10rem;
    background-color: #FFD800;
    box-shadow: 0 1rem 3rem rgba(0, 0, 0, .175) !important;
    position: fixed;
    padding: 1rem;
    left: 0;
    top: 51.8%;
    transform: translate(-100%, -50%);
    transition: all .5s ease-in-out;
}

.color-switcher--open{
    transform: translate(0, -50%);
}


.color-list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;
}

.color-item {
    width: 2rem;
    height: 2rem;
    /* border-radius: 50%; */
    background-color: var(--bg-color);
}

.color-switcher button {
    width: 3rem;
    height: 3rem;
    background-color: #FFD800;
    border: none;
    position: absolute;
    font-size: 1.2rem;
    color: #1a1a1a;
    right: 0;
    top: 50%;
    transform: translate(100%, -117%);
    display: grid;
    place-items: center;
    cursor: pointer;
}