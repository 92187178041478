

.AboutUsFirstContent p {
    color: #808080;
    font-family: "Laila", serif;
    font-weight: 400;
    margin-right: 20px;
    font-size: 14.2px;
    cursor: default;
    line-height: 24px;
    margin-bottom: 15px;
}

.AboutUsFirstContent p span{
    color: #FFD800;
    font-family: "Laila", serif;
    font-weight: 600;
    font-size: 14.5px;
    cursor: default;
    line-height: 24px;
    margin-bottom: 15px;
}

.AboutUsFirstTeamContent {
    text-align: center;
}

.AboutUsFirstTeamContent h2 {
    font-family: "Merriweather", serif;
    color: #fff;
    font-weight: 700;
    font-size: 30px;
    margin-bottom: 20px;
    line-height: 46px;
}

.AboutUsFirstTeamContent p {
    color: #fff;
    font-family: "Laila", serif;
    font-weight: 400;
    font-size: 18.5px;
    line-height: 27px;
    margin-left: auto;
    margin-right: auto;
    max-width: 700px;
    margin-bottom: 25px;
}

.AboutUsFirstOurTeam {
    text-align: center;
    padding: 25px 20px 0px;
}

.AboutUsFirstOurTeam h2 {
    color: #fff;
    font-family: "Merriweather", serif;
    font-weight: 800;
    line-height: 27px;
    font-size: 22px;
    margin-top: 5px;
    margin-bottom: 5px;
    cursor: pointer;
}

.AboutUsFirstOurTeam h6 {
    color: #909090;
    font-family: "Laila", serif;
    font-weight: 400;
    font-size: 18px;
    cursor: default;
    line-height: 24px;
    margin-bottom: 15px;
}

.AboutUsFirstOurTeam p {
    color: #808080;
    font-family: "Laila", serif;
    font-weight: 400;
    font-size: 14px;
    cursor: default;
    line-height: 24px;
    margin-bottom: 10px;
}

.SocialIconAboutUs {
   padding: 5px;
}

.SocialIconAboutUs i {
    font-size: 13px;
    color: #808080;
    margin-left: 15px;
    border-radius: 25px;
}

.SocialIconAboutUs i:hover {
    font-size: 13px;
    color: #ffd800;
    margin-left: 15px;
    border-radius: 25px;
}

.Feature-Content-AboutUs {
    font-family: "Merriweather", serif;
    margin-right: 20px;
}

.Feature-Content-AboutUs h2 {
    color: #1a1a1a;
    font-weight: 800;
    font-size: 14px;
    margin-bottom: 12px;
    margin-top: 20px;
}

.Feature-Content-AboutUs p {
    color: #808080;
    font-family: "Laila", serif;
    font-weight: 400;
    font-size: 13px;
    line-height: 1.7842;
    margin-bottom: 10px;
}